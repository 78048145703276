import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Button,
  CircularProgress,
  InputAdornment,
  InputLabel,
  TextField,
  createTheme,
} from "@mui/material";
import CardSelect from "../components/cards/CardSelect";
import CardSex from "../components/cards/CardSex";
import Apple from "../resources/icons/apple.png";
import HeartRate from "../resources/icons/heartRate.png";
import MaleIcon from "../resources/icons/maleIcon.png";
import Completed from "../resources/completedSurvey.png";
import FemaleIcon from "../resources/icons/femaleIcon.png";
import Continue from "../resources/continueSurvey.png";
import Verified from "../resources/icons/verified.png";
import LogoDark from "../resources/logoDark.png";
import Scale from "../resources/icons/scale.png";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import type { UserSurvey } from "../models/UserModel";
import dayjs, { Dayjs } from "dayjs";
import { useNavigate } from "react-router-dom";
import { setUser } from "../state/user/userSlice";
import { post } from "../service/apiService";

function Survey() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [currentTitle, setCurrentTitle] = useState<string>("الرجاء كتابة اسمك");
  const [currentInfo, setCurrentInfo] = useState<string>(
    ".سنقوم بجمع بعض البيانات عنك لاستعمالها في توفير أفضل تجربة لك داخل التطبيق"
  );
  const [chalories, setChalories] = useState<number>(0);
  const [currentError, setCurrentError] = useState<string>("");

  //texts consts
  const finalStepText = [
    "تحصلت على 1 يوم مجاني",
    "برنامجك الصحي يتضمن خسارة 0.5 كجم إسبوعيًا",
    `وجباتك ستضمن لك معدل ${chalories} سعرة يوميًا`,
    "يجب عليك المداومة على ما لا يقل عن 2 لتر مياه يوميًا",
  ];
  const startUsingText = "البدء في الاستخدام";
  const [selectedWeightCardId, setSelectedWeightCardId] = useState<
    number | null
  >(null); // Initially no selection
  const [selectedBodyTypeCard, setSelectedBodyTypeCard] =
    useState<string>("None");

  const [dateOfBirth, setDateOfBirth] = useState<Dayjs | null>(null);
  const [cardSelect, setCardSelect] = useState<number | null>(null);
  const [height, setHeight] = useState<any>("");
  const [weight, setWeight] = useState<any>("");
  const [tw, setTw] = useState<any>("");
  const [userInfo, setUserInfo] = useState<UserSurvey>({
    firstName: "",
    surname: "",
    dateOfBirth: "",
    gender: "",
    height: 0,
    currentWeight: 0,
    targetWeight: 0,
    activityLevels: "",
    bodyTypes: "",
  });

  useEffect(() => {
    const signupComplete = localStorage.getItem("isSignUpCompleted");
    const formattedIsSignupComplete = signupComplete === "true";
    if (localStorage.getItem("accessToken") !== null) {
      if (formattedIsSignupComplete === true) {
        navigate("/");
      }
    } else navigate("/login");
  }, []);

  useEffect(() => {
    if (height) {
      setUserInfo((prevState) => ({ ...prevState, height: height }));
    }
    if (weight) {
      setUserInfo((prevState) => ({ ...prevState, currentWeight: weight }));
    }
    if (tw) {
      setUserInfo((prevState) => ({
        ...prevState,
        targetWeight: tw,
      }));
    }
  }, [height, weight, tw]);

  useEffect(() => {
    setTextAccordingToStep();
  }, [currentStep]);
  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };
  const handleDateChange = (date: any) => {
    setUserInfo((prevState) => ({
      ...prevState,
      dateOfBirth: date.toISOString(),
    }));
  };

  const handleWeightCardSelect = (selectedOption: number) => {
    // Do something with the selected option
    setSelectedWeightCardId(selectedOption);
  };
  const handleActivityLevelsCardSelect = (selectedOption: number) => {
    // Do something with the selected option
    setUserInfo((prev) => ({
      ...prev,
      activityLevels: "Level" + selectedOption,
    }));
  };
  const handleBodyTypeCardSelect = (selectedOption: number) => {
    // Do something with the selected option
    setUserInfo((prev) => ({ ...prev, bodyTypes: "Level" + selectedOption }));
  };
  const handleCardSelect = (selectedOption: string) => {
    // Do something with the selected option
    setUserInfo((prevState) => ({ ...prevState, gender: selectedOption }));
  };
  const imageContinueClicked = () => {
    switch (currentStep) {
      case 1:
        if (userInfo.firstName === "") {
          setCurrentError("الرجاء كتابة اسمك");
          return;
        } else {
          setCurrentError("");
        }
        break;
      case 2:
        if (selectedWeightCardId === null) {
          setCurrentError("الرجاء اختيار هدفك الحالي");
          return;
        } else {
          setCurrentError("");
        }
        break;
      case 3:
        if (userInfo.dateOfBirth === "") {
          setCurrentError("الرجاء كتابة تاريخ ميلادك");
          return;
        } else {
          setCurrentError("");
        }
        break;
      case 4:
        if (userInfo.gender === "") {
          setCurrentError("الرجاء اختيارالجنس");
          return;
        } else {
          setCurrentError("");
        }
        break;
      case 5:
        if (userInfo.height === 0) {
          setCurrentError("الرجاء كتابة طولك الحالي");
          return;
        } else {
          setCurrentError("");
        }
        break;
      case 6:
        if (userInfo.currentWeight === 0) {
          setCurrentError("الرجاء كتابة وزنك الحالي");
          return;
        } else {
          setCurrentError("");
        }
        break;
      case 7:
        if (userInfo.targetWeight === 0) {
          setCurrentError("الرجاء كتابة الوزن الذي تطمح بالوصول إليه");
          return;
        } else {
          setCurrentError("");
        }
        break;
      case 8:
        if (userInfo.activityLevels === "") {
          setCurrentError("الرجاء اختيار مدى نشاطك اليومي");
          return;
        } else {
          setCurrentError("");
        }
        break;
      case 9:
        if (userInfo.bodyTypes === "") {
          if (userInfo.bodyTypes === "") {
            setCurrentError("الرجاء اختيار نوع الجسم");
            return;
          } else {
            setCurrentError("");
          }
        }
    }
    if (currentStep === 9) {
      submitFinishSignup();
    }

    setCurrentStep(currentStep + 1);
  };

  const handleHeightChange = (e: any) => {
    const value = e.target?.value;
    if (value === "" || /^[0-9\b]+$/.test(value)) {
      setHeight(value);
    }
  };
  const handleWeightChange = (e: any) => {
    const value = e.target?.value;
    if (value === "" || /^[0-9\b]+$/.test(value)) {
      setWeight(value);
    }
  };

  const handleTwChange = (e: any) => {
    const value = e.target?.value;
    if (value === "" || /^[0-9\b]+$/.test(value)) {
      setTw(value);
    }
  };

  const setDivAccordingToStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="w-50 d-flex justify-content-center">
            <TextField
              id="outlined-basic"
              label="اسمك"
              variant="outlined"
              onChange={(e) =>
                setUserInfo((prevState) => ({
                  ...prevState,
                  firstName: e.target.value.split(" ")[0],
                  surname: e.target.value.split(" ")[1],
                }))
              }
            />
          </div>
        );
      case 2:
        return (
          <>
            <CardSelect
              text="خسارة الوزن"
              idNumber={1}
              icon={Apple}
              selected={selectedWeightCardId === 1} // Pass selected state based on selectedWeightCardId
              onClick={() => handleWeightCardSelect(1)}
            />
            <CardSelect
              text="زيادة الوزن"
              idNumber={2}
              icon={Scale}
              selected={selectedWeightCardId === 2} // Pass selected state based on selectedWeightCardId
              onClick={() => handleWeightCardSelect(2)}
            />
            <CardSelect
              text="الصحة"
              idNumber={3}
              icon={HeartRate}
              selected={selectedWeightCardId === 3} // Pass selected state based on selectedWeightCardId
              onClick={() => handleWeightCardSelect(3)}
            />
          </>
        );
      case 3:
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="D/M/YYYY"
              value={dayjs(userInfo.dateOfBirth)}
              onChange={(date) => handleDateChange(date)}
            />
            {/* <TextField
              id="outlined-basic"
              label="العمر/تاريخ الميلاد"
              variant="outlined"
              fullWidth
            /> */}
          </LocalizationProvider>
        );
      case 4:
        return (
          <div className="cards-container sex">
            <CardSex
              text="ذكر"
              icon={MaleIcon}
              idNumber={2}
              selected={userInfo.gender === "Male"} // Pass selected state based on selectedWeightCardId
              onClick={() => handleCardSelect("Male")}
            />
            <CardSex
              text="انثى"
              icon={FemaleIcon}
              idNumber={1}
              selected={userInfo.gender === "Female"} // Pass selected state based on selectedWeightCardId
              onClick={() => handleCardSelect("Female")}
            />
          </div>
        );
      case 5:
        return (
          <div className="w-50 d-flex justify-content-center" dir="ltr">
            <TextField
              id="outlined-basic"
              label="طولك الحالي"
              variant="outlined"
              value={height}
              onChange={(e) => {
                handleHeightChange(e);
              }}
              inputProps={{ maxLength: 3 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">سم</InputAdornment>
                ),
              }}
            />
          </div>
        );
      case 6:
        return (
          <div className="w-50 d-flex justify-content-center" dir="ltr">
            <TextField
              id="outlined-basic"
              label="وزنك الحالي"
              variant="outlined"
              value={weight}
              onChange={(e) => handleWeightChange(e)}
              inputProps={{ maxLength: 3 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">كج</InputAdornment>
                ),
              }}
            />
          </div>
        );
      case 7:
        return (
          <div className="w-50 d-flex justify-content-center" dir="ltr">
            <TextField
              id="outlined-basic"
              label="الوزن الذي تطمح بالوصول إليه"
              variant="outlined"
              value={tw}
              onChange={(e) => handleTwChange(e)}
              inputProps={{ maxLength: 3 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">كج</InputAdornment>
                ),
              }}
            />
          </div>
        );
      case 8:
        return (
          <>
            <CardSelect
              text="لا أمارس الرياضة"
              idNumber={1}
              selected={userInfo.activityLevels === "Level1"} // Pass selected state based on selectedWeightCardId
              radio={true}
              onClick={() => handleActivityLevelsCardSelect(1)}
            />
            <CardSelect
              text="أمارس بعض التمارين الخفيفة"
              idNumber={2}
              radio={true}
              selected={userInfo.activityLevels === "Level2"} // Pass selected state based on selectedWeightCardId
              onClick={() => handleActivityLevelsCardSelect(2)}
            />
            <CardSelect
              text="أمارس الرياضة بشكل يومي"
              idNumber={3}
              radio={true}
              selected={userInfo.activityLevels === "Level3"} // Pass selected state based on selectedWeightCardId
              onClick={() => handleActivityLevelsCardSelect(3)}
            />
          </>
        );
      case 9:
        return (
          <>
            <CardSelect
              text="جسم نحيف"
              idNumber={1}
              selected={userInfo.bodyTypes === "Level1"} // Pass selected state based on selectedWeightCardId
              radio={true}
              onClick={() => handleBodyTypeCardSelect(1)}
            />
            <CardSelect
              text="جسم معتدل"
              idNumber={2}
              radio={true}
              selected={userInfo.bodyTypes === "Level2"} // Pass selected state based on selectedWeightCardId
              onClick={() => handleBodyTypeCardSelect(2)}
            />
            <CardSelect
              text="جسم ممتلئ"
              idNumber={3}
              radio={true}
              selected={userInfo.bodyTypes === "Level3"} // Pass selected state based on selectedWeightCardId
              onClick={() => handleBodyTypeCardSelect(3)}
            />
          </>
        );
      case 10:
        return (
          <>
            {finalStepText.map((item) => {
              return (
                <div className="flex-row justify-content-start align-items-center w-100 mt-4">
                  <img src={Verified} className="icon-img" />
                  {item}
                </div>
              );
            })}
          </>
        );
      default:
        return <></>;
    }
  };
  const setTextAccordingToStep = () => {
    switch (currentStep) {
      case 1:
        setCurrentTitle("الرجاء كتابة اسمك");

        break;
      case 2:
        setCurrentTitle("ماهو هدفك الحالي؟");

        break;
      case 3:
        setCurrentTitle("العمر/تاريخ الميلاد");

        break;
      case 4:
        setCurrentTitle("الرجاء تحديد جنسك");

        break;
      case 5:
        setCurrentTitle("يرجى كتابة طولك الحالي");

        break;
      case 6:
        setCurrentTitle("يرجى كتابة وزنك الحالي");

        break;
      case 7:
        setCurrentTitle("ما هو الوزن الذي تطمح بالوصول إليه؟");
        break;
      case 8:
        setCurrentTitle("ما هو مدى نشاطك اليومي؟");
        break;
      case 9:
        setCurrentTitle("اختر الوصف الملائم لطبيعة جسمك");

        break;
    }
  };

  const submitFinishSignup = () => {
    post("identity/complete-signup", userInfo)
      .then((item) => {
        setCurrentError("");
        localStorage.setItem(
          "userProfileDto",
          JSON.stringify(item.userProfileDto)
        );
        localStorage.setItem("accessToken", item.accessToken);
        localStorage.setItem(
          "isSignUpCompleted",
          JSON.stringify(item.isSignUpCompleted)
        );
        setChalories(item.userProfileDto.totalDailyCalCons);
      })
      .catch((e) => {
        setCurrentError("something went wrong! please contact support");
      });
    // navigate("/main");
  };
  return (
    <div className="w-100 h-100 mb-5 d-flex justify-content-center align-items-center">
      <div className="survey-container">
        {currentStep !== 10 && (
          <div className="button-container">
            <>
              <Button
                className="text-success px-2"
                disabled={currentStep === 1}
                color="success"
                startIcon={<KeyboardArrowRightIcon />}
              >
                <p className="m-0 me-2">الرجوع للخطوة السابقة</p>
              </Button>
            </>
          </div>
        )}
        {currentStep !== 10 ? (
          <h2>{"9/" + currentStep}</h2>
        ) : (
          <img src={Completed} className="completed-title-img" />
        )}
        <p className="text-center fw-bold fs-1">
          {currentStep !== 10
            ? "" + currentTitle
            : "أنت جاهز لبدء رحلة الوصول لوزنك المثالي!"}
        </p>
        {currentStep !== 10 && (
          <p className="mb-5 text-center">{"" + currentInfo}</p>
        )}
        <div className={`cards-container`}>
          {currentStep !== 10 ? (
            <>
              {setDivAccordingToStep()}
              <InputLabel shrink className="mt-2 text-danger">
                {currentError ? currentError : ""}
              </InputLabel>
            </>
          ) : chalories ? (
            <>
              {setDivAccordingToStep()}
              <InputLabel shrink className="mt-2 text-danger">
                {currentError ? currentError : ""}
              </InputLabel>
            </>
          ) : (
            <>
              <CircularProgress color="success" />
            </>
          )}
        </div>
        {currentStep !== 10 ? (
          <div className="continue-survey-btn-container">
            <img
              className="img-button "
              src={Continue}
              onClick={imageContinueClicked}
            />
          </div>
        ) : chalories ? (
          <div className="start-use-btn d-flex flex-column justify-content-center align-items-center w-100">
            <InputLabel shrink className="mt-2 text-danger">
              {currentError ? currentError : ""}
            </InputLabel>
            <Button
              variant="contained"
              color="success"
              className="w-50 h-100 text-white mb-5"
              onClick={() => navigate("/")}
            >
              {startUsingText}
            </Button>
            <img
              className="logo-img-small mt-5"
              src={LogoDark}
              alt="React Logo"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Survey;
