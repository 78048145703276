import { Button, IconButton } from "@mui/material";
import LogoWhite from "../resources/logo.png";
import React from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

function Footer() {
  const goToTopOfThePage = () => {
    window.scrollTo(0, 0);
  };
  const navigateToPrivacyAr = () => {
    window.open("/privacy-ar", "_blank");
  };

  const navigateToOnSeperateTab = (url: string) => {
    window.open(url, "_blank");
  };
  return (
    <div className="footer-container w-100 p-3">
      <div className="d-flex flex-row justify-content-between ">
        <div className="d-flex flex-row align-items-center justify-content-center">
          <IconButton
            className=" text-white"
            aria-label="arrow-up"
            onClick={goToTopOfThePage}
            size="large"
          >
            <ArrowUpwardIcon />
          </IconButton>
          <p className="m-0">إنتقل للأعلى</p>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="social-media-container justify-content-center">
            <IconButton
              aria-label="facebook"
              size="large"
              onClick={() =>
                navigateToOnSeperateTab("https://www.facebook.com/Dietfitapp")
              }
              color="success"
            >
              <FacebookIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="instagram"
              onClick={() =>
                navigateToOnSeperateTab("https://www.instagram.com/dietfitapp")
              }
              size="large"
              color="success"
            >
              <InstagramIcon fontSize="inherit" />
            </IconButton>
          </div>
          <div dir="ltr" className="d-flex align-items-center">
            © 2024 dietfit. All rights reserved.
          </div>
          <Button
            variant="text"
            color="secondary"
            className="fw-bold"
            onClick={() => navigateToPrivacyAr()}
          >
            سياسة الخصوصية لدايت فيت
          </Button>
        </div>
        <img src={LogoWhite} alt="logo-white" className="logo-footer" />
      </div>
      <div></div>
    </div>
  );
}

export default Footer;
