import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState, useEffect, useRef } from "react";
import { RootState } from "../state/store";
import { useSelector, useDispatch } from "react-redux";
import { post, get } from "../service/apiService";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import {
  handleErrorsAccordingToStatusUserLogin,
  handleErrorsAccordingToStatusUserOtp,
} from "../utils/helperFunctions";
import { setUser } from "../state/user/userSlice";
import { useNavigate } from "react-router-dom";
import { login } from "../state/auth/authSlice";
import TermsAndConditionsDialog from "../components/Dialog/TermsAndConditionsDialog";
import AlertDialog from "../components/Dialog/AlertDialog";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);

  const [isLogin, setIsLogin] = useState(true);
  const [isRegister, setIsRegister] = useState(false);
  const [authCode, setAuthCode] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [authCodeValue, setAuthCodeValue] = useState();
  const [deviceType, setDeviceType] = useState("Web");
  const [transactionIdentify, setTransactionIdentify] = useState("");
  const [error, setError] = useState();
  const [termsAndConditions, setTermsAndConditions] = useState(true);
  const [termsAndConditionsText, setTermsAndConditionsText] = useState("");
  const [loading, setLoading] = useState(false);
  const [showDesc, setShowDesc] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [data, setData] = useState(null);
  const [subMessage, setSubMessage] = useState("");
  useEffect(() => {
    connexScript();
    get("/Terms").then((data) => {
      setTermsAndConditionsText(data.content);
    });
    // return () => {
    //   // Clean up the script when the component unmounts
    //   document.body.removeChild(script);
    // };
  }, []);

  const connexScript = () => {
    get("https://dev.dietfit.app/Connex/is-active").then((data) => {
      setShowDesc(data.isActive);
    });

    setLoading(true);
    get("/Connex/request-protected-script").then((data) => {
      setTransactionIdentify(data.transactionIdentify);
      localStorage.setItem("transactionIdentify", data.transactionIdentify);
      const dcbProtect = data.dcbProtect;
      // Dynamically inject the script into the page
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.text = dcbProtect;
      document.head.appendChild(script);

      // Dispatch custom event
      const event = new Event("DCBProtectRun");
      document.dispatchEvent(event);
      setLoading(false);
    });
  };

  const onPhoneSubmit = async () => {
    const phNumber = `218${phoneNumber}`;
    if (phoneNumber.length > 0) {
      try {
        console.log("inside onPhoneSubmit");
        const res = await post(`/identity/otp`, {
          phoneNumber: phNumber,
          deviceType: deviceType,
          transactionIdentify: transactionIdentify,
        });
        if (res) setAuthCode(true);
        // setOpenAlert(true)
        return res;
      } catch (e) {
        if (e?.response) {
          if (e.response.data !== "" && e.response.data !== undefined) {
            setError(e.response.data);
          } else
            setError(handleErrorsAccordingToStatusUserOtp(e.response.status));
        }
      }
    } else {
      setError("الرجاء إدخال رقم الهاتف");
    }
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleAccept = () => {
    setTermsAndConditions(true);
    setOpenDialog(false);
  };

  // const onSubmit = () => {
  //   const phNumber = `218${phoneNumber}`;
  //   setError("");
  //   const reqObj = {
  //     phoneNumber: phNumber,
  //     otp: authCodeValue,
  //     deviceType: deviceType,
  //     transactionIdentify: transactionIdentify,
  //   };
  //   if (phoneNumber.length !== 0) {
  //     post(`/identity/login`, reqObj)
  //       .then((data) => {
  //         localStorage.setItem("accessToken", data.accessToken);
  //         localStorage.setItem("refreshToken", data.refreshToken);
  //         localStorage.setItem("tokenExpirationUtc", data.tokenExpirationUtc);
  //         localStorage.setItem(
  //           "userProfileDto",
  //           JSON.stringify(data.userProfileDto)
  //         );
  //         localStorage.setItem("isSignUpCompleted", data.isSignUpCompleted);
  //         setData(data);
  //         if (window?.uni && window?.uni.postMessage) {
  //           // Call the postMessage method defined in the loaded script
  //           window.uni.postMessage({ data: data });
  //         } else {
  //           console.error("uni.postMessage is not available");
  //         }
  //         dispatch(setUser(data.userProfileDto));
  //         dispatch(login());
  //       })
  //       .catch((e) => {
  //         if (e.response?.data !== "" && e.response?.data !== undefined) {
  //           setError(e.response.data);
  //         } else
  //           setError(
  //             handleErrorsAccordingToStatusUserLogin(e.response?.status)
  //           );
  //       });
  //   } else {
  //     setError("الرجاء إدخال رقم الهاتف");
  //   }
  // };

  const onSignup = () => {
    const phNumber = `218${phoneNumber}`;
    setError("");
    const signUpObj = {
      phoneNumber: phNumber,
      otp: authCodeValue,
      deviceType: deviceType,
      transactionIdentify: transactionIdentify,
    };
    if (phoneNumber.length > 0) {
      post(`/Identity/signup`, signUpObj)
        .then((data) => {
          window.postMessage({ action: "regSuccess", token: data.accessToken });
          localStorage.setItem("accessToken", data.accessToken);
          localStorage.setItem("refreshToken", data.refreshToken);
          localStorage.setItem("tokenExpirationUtc", data.tokenExpirationUtc);
          localStorage.setItem(
            "userProfileDto",
            JSON.stringify(data.userProfileDto)
          );
          localStorage.setItem("isSignUpCompleted", data.isSignUpCompleted);

          if (window?.uni && window?.uni.postMessage) {
            // Call the postMessage method defined in the loaded script
            window.uni.postMessage({ data: data });
          } else {
            console.error("uni.postMessage is not available");
          }
          dispatch(setUser(data.userProfileDto));
          dispatch(login());
          if (data.userProfileDto.isSignUpCompleted) {
            navigate("/");
          } else {
            navigate("/survey");
          }
        })
        .catch((e) => {
          if (e.response.data !== "" && e.response.data !== undefined) {
            setError(e.response.data);
          } else
            setError(handleErrorsAccordingToStatusUserLogin(e.response.status));
        });
    } else {
      setError("الرجاء إدخال رقم الهاتف");
    }
  };
  const navigateToPrivacyAr = () => {
    window.open("/privacy-ar", "_blank");
  };
  const returnMain = () => {
    setError("");

    setAuthCode(false);
  };

  const logInConfrim = () => {
    setError("");
    setLoading(true);
    onPhoneSubmit()
      .then((data) => {
        setLoading(false);
        get(`/Profile/subscription-message?phoneNumber=218${phoneNumber}`).then(
          (res) => {
            setSubMessage(res);
            if (res.length > 0) {
              setOpenAlert(true);
            }
          }
        );
      })
      .catch((e) => {
        setLoading(false);

        if (e.response.data !== "" && e.response.data !== undefined) {
          setError(e.response.data);
        } else
          setError(handleErrorsAccordingToStatusUserLogin(e.response.status));
      })
      .catch((e) => {
        setLoading(false);
        if (e.response.data !== "" && e.response.data !== undefined) {
          setError(e.response.data);
        } else
          setError(handleErrorsAccordingToStatusUserLogin(e.response.status));
      });
  };
  const registerConfirm = () => {
    setLoading(true);
    if (!termsAndConditions) {
      setLoading(false);
      setError("الرجاء الموافقة على شروط الاستخدام");
      return;
    }
    setError("");

    onPhoneSubmit()
      .then((data) => {
        get(`/Profile/subscription-message?phoneNumber=218${phoneNumber}`).then(
          (res) => {
            setSubMessage(res);
            setLoading(false);
            if (res.length > 0) {
              setOpenAlert(true);
            }
          }
        );
      })
      .catch((e) => {
        console.log("inside catch");
        setLoading(false);
        if (e.response.data !== "" && e.response.data !== undefined) {
          setError(e.response.data);
        } else
          setError(handleErrorsAccordingToStatusUserLogin(e.response.status));
      });
  };

  const radioClicked = () => {
    setTermsAndConditions(!termsAndConditions);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target?.value;

    if (value === "" || /^[0-9\b]+$/.test(value)) {
      setPhoneNumber(value);
    }
  };
  const handleOtpChange = (e) => {
    const value = e.target?.value;
    if (value === "" || /^[0-9\b]+$/.test(value)) {
      setAuthCodeValue(value);
    }
  };

  const handleSubmit = () => {};

  const navigateToOnSeperateTab = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="login-container">
      <img className="logo-img cptpl_logo" src="logo.png" alt="React Logo" />
      <div className="login-form-container text-white">
        {!isRegister && (
          <>
            <h2 className="text-white">مرحبًا بك في دايت فت!</h2>
            <p className="text-white-50">رحلتك للجسم المثالي تبدأ من هنا...</p>
          </>
        )}
        {isRegister && !authCode && (
          <>
            <h2 className="text-white"> مرحبا بك في دايت فت</h2>
          </>
        )}
        {authCode && (
          <>
            <h2 className="text-white">رمز التحقق</h2>
          </>
        )}
        <FormControl className="input-form" dir="ltr">
          {(isLogin || isRegister) && !authCode ? (
            <>
              <TextField
                variant="outlined"
                id="my-input"
                label="رقم الهاتف"
                aria-describedby="phone-number"
                className="input-textfield"
                placeholder="9********"
                value={phoneNumber}
                onChange={(e) => handlePhoneNumberChange(e)}
                inputProps={{
                  maxLength: 9,
                }}
                color="success"
                focused
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="login-field">
                      218
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText id="my-helper-text"></FormHelperText>
            </>
          ) : authCode ? (
            <>
              <TextField
                variant="outlined"
                id="my-input"
                label="رمز التحقق"
                aria-describedby="auth-code"
                className="input-textfield"
                value={authCodeValue || ""}
                onChange={(e) => handleOtpChange(e)}
                inputProps={{
                  maxLength: 4,
                }}
                color="success"
                focused
              />
              <FormHelperText id="my-helper-text"></FormHelperText>
            </>
          ) : (
            <></>
          )}
          {error && <p className="desc-text">{error}</p>}
          {/* <Button
              variant="contained"
              color="success"
              className=" my-2 text-white login-btn"
              loading={loading}
              id="cta_button"
              onClick={() =>
                !isLogin && !isRegister
                  ? setIsLogin(true)
                  : isLogin && !authCode
                  ? logInConfrim()
                  : authCode && isRegister
                  ? onSignup()
                  : authCode && isLogin
                  ? onSubmit()
                  : registerConfirm()
              }
            >
              {authCode
                ? "متابعة"
                : isLogin
                ? "تسجيل الدخول"
                : isRegister
                ? "إنشاء حساب جديد"
                : "تسجيل الدخول"}
            </Button> */}
          <Button
            variant="contained"
            color="success"
            className=" my-2 text-white login-btn cptpl_subscribe"
            loading={loading}
            loadingPosition="end"
            id="cta_button"
            onClick={() => (authCode ? onSignup() : registerConfirm())}
          >
            {authCode ? "متابعة" : "اشترك"}
          </Button>
          {/* {isLogin && !authCode && (
              <Button
                variant="outlined"
                color="success"
                className="my-2 text-white login-btn"
                onClick={() => {
                  setIsRegister(true);
                  setIsLogin(false);
                }}
              >
                إنشاء حساب جديد
              </Button>
            )} */}
          {authCode && (
            <Button
              variant="outlined"
              color="success"
              className="my-2 text-white login-btn"
              disabled={loading}
              onClick={() => {
                returnMain();
              }}
            >
              رجوع
            </Button>
          )}
          {openAlert && <p className="desc-text text-warning"> {subMessage}</p>}
          {showDesc ? (
            <>
              <p className="desc-text text-white cptpl_service">
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => setOpenDialog(true)}
                >
                  <span className="highlight-text"> الشروط والاحكام</span>
                </Button>
                بالنقر على اشترك انت توافق على
              </p>
              <p className="desc-text text-white-50">
                تطبيق يساعدك على الوصول للوزن المناسب بطرق صحية وآمنة
                <br /> يضمن لك دايت فت خطة غذائية يومية (بمعدل 4 وجبات / اليوم)
              </p>
              <p className="desc-text text-white-50 cptpl_price">
                يوم مجاني ومن ثم 0.5 دينار لمستخدمي ليبيانا يومياً و 2.5 دينار
                لمستخدمي المدار اسبوعياً
              </p>
            </>
          ) : (
            <></>
          )}
        </FormControl>
      </div>
      {/* <div className="social-media-container">
        <IconButton
          aria-label="facebook"
          size="large"
          onClick={() =>
            navigateToOnSeperateTab("https://www.facebook.com/Dietfitapp")
          }
          color="success"
        >
          <FacebookIcon fontSize="inherit" />
        </IconButton>
        <IconButton
          aria-label="instagram"
          onClick={() =>
            navigateToOnSeperateTab("https://www.instagram.com/dietfitapp")
          }
          size="large"
          color="success"
        >
          <InstagramIcon fontSize="inherit" />
        </IconButton>
      </div> */}
      <div>
        {/* <Button
          variant="text"
          color="secondary"
          className="fw-bold"
          onClick={() => navigate("/support")}
        >
          الدعم
        </Button> */}
        <Button
          variant="text"
          color="secondary"
          className="fw-bold"
          onClick={() => navigateToPrivacyAr()}
        >
          سياسة الخصوصية لدايت فيت
        </Button>
      </div>
      <span className="cptpl_terms"></span>
      <TermsAndConditionsDialog
        data={termsAndConditionsText}
        open={openDialog}
        onClose={handleClose}
        accept={handleAccept}
      />
      {/* <AlertDialog
        open={openAlert}
        handleCancel={handleSubmit}
        text={subMessage}
        title={"الإشتراك"}
      /> */}
    </div>
  );
}
export default Login;
